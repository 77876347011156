import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyC_44sTT4cGsd9yGMhm9YU4TzSq7zE3CdM",
    authDomain: "glassist-c0be2.firebaseapp.com",
    databaseURL: "https://glassist-c0be2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "glassist-c0be2",
    storageBucket: "glassist-c0be2.appspot.com",
    messagingSenderId: "60531876998",
    appId: "1:60531876998:web:20532f6e661c38a89b9d07",
    measurementId: "G-G8NFVNPVNX"
  };

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
export { database };
const realtimedb = getDatabase(app);
export { realtimedb };
const storage = getStorage(app)
export { storage }
export const auth = getAuth(app);