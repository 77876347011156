import React from "react";
import "../style.css";
import Image from "../glassist_logo.png";
import Profile from "../profile.png";
import {auth} from "../Firebase";
import {Link} from "react-router-dom";
// import { Image } from "@nextui-org/react";

function Home() {
  const user = auth.currentUser;
  return (
    <body>
      <div class="profile">
        <Link to={!user ? "/signup" : "/dashboard"}>
          <img src={Profile} class="profileLogo" alt="profile" />
        </Link>
      </div>
      <div class="home">
        <div class="button">
          <form action="#" class="button-form">
            <a href="/periksa">Periksa</a>
            <a href="/riwayat">Riwayat</a>
            {/*<a href="/biodata">Biodata</a>*/}
          </form>
        </div>
        <div class="panels-home">
          <div class="panel left-panel">
            <img src={Image} class="glassist" alt="logo_glassist" />
          </div>
        </div>
      </div>
    </body>
  );
}

export default Home;